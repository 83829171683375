
import request from '@/utils/request'
import qs from 'qs'

// 待审核稿件列表数据
export function listdata (id,page,limit,state) {
  let data = {
    id,page,limit,state
  }
  data = qs.stringify(data)
  return request({
    url: '/qikan/TobeReviewed',
    method: 'post',
    data
  })
}
// 待结算稿件列表数据
export function unbanlancedata (qk_id,page,limit,keywords) {
  let data = {
   qk_id,page,limit,keywords
  }
  data = qs.stringify(data)
  return request({
    url: '/qikan/daijiesuan',
    method: 'post',
    data
  })
}
// 已结算稿件列表数据
export function balancedata (qk_id,page,limit) {
  let data = {
    qk_id,page,limit
  }
  data = qs.stringify(data)
  return request({
    url: '/qikan/yijiesuan',
    method: 'post',
    data
  })
}
// 期刊驳回稿件// 补录通过通知
export function BoHui (id,page,limit,state) {
  let data = {
    id,page,limit,state
  }
  data = qs.stringify(data)
  return request({
    url: '/qikan/TobeApproval',
    method: 'post',
    data
  })
}

