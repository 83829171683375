<template>
  <div>
    <div class="qkxx_title">待结算稿件</div>
    <div class="btn1">
      <el-button @click="back()">返回</el-button>
      <el-button type="danger" @click="balance">确定</el-button>
    </div>
    <div class="qkcont">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="42"> </el-table-column>

        <el-table-column
          prop="address"
          show-overflow-tooltip
          label="文章标题"
          sortable
        >
          <template slot-scope="scope">
            <div class="ordertable">
              <div class="ygdleft">
                <p class="biaoti">{{ scope.row.title }}</p>
                <p>
                  <span>{{ scope.row.name }}</span
                  ><span>{{ scope.row.editor }}</span>
                </p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          show-overflow-tooltip
          label="按投稿时间"
          sortable
        >
          <template slot-scope="scope">
            <div class="ygdright">
              <div style="height: 20px"></div>
              <span
                ><i class="el-icon-s-promotion"></i>投稿时间：{{
                  scope.row.operation_time
                }}</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column label="是否结算" prop="Approval_time" sortable>
          <template slot-scope="scope">
            <div class="ordertable">
              <div class="ygdright">
                <div style="height: 20px"></div>
                <span v-if="(scope.row.state == 1)">已申请结算</span>
                <span v-if="(scope.row.state == 0)">未申请结算</span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="ygdPage">
        <el-pagination
          class="fy"
          small
          @size-change="handleSizeChange"
          :page-size="pagesize"
          :page-sizes="[10, 20, 30]"
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="current_change"
          :total="total"
          background
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import order from '../components/order'
import { unbanlancedata } from "../../../API/QKD/list.js"
import { orderbalance } from "../../../API/QKD/balance.js"
export default {
  components: {
    order
  },
  data () {
    return {
      son: 1,
      path: '/Periodical/unbalanced',
      childpath: '/Periodical/balanced/detail',
      total: 0, //默认数据总数
      pagesize: 10, //每页的数据条数
      currentPage: 1, //默认开始页面
      tableData: [ //订单列表数据

      ],
      keywords:'',
      orderid: []

    }
  },
  methods: {
    // 分页
    current_change: function (currentPage) {
      this.currentPage = currentPage;
      this.orderdata();
    },
    handleSizeChange (val) {
      console.log(val);
      this.pagesize = val;
      this.orderdata();
    },
    orderdata () {
      let id = localStorage.getItem('QKId')
      unbanlancedata(id, this.currentPage, this.pagesize,this.keywords).then(res => {
        console.log(res)
        this.tableData = res.data.data;
        this.total = res.data.total
      })
    },
    back () {
      this.$router.push("/Periodical/unbalanced/?id=8")
    },
    handleSelectionChange (val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection);
      console.log(val)
      this.orderid = [];
      for (let i of this.multipleSelection) {
        this.orderid.push(i.id)
        console.log(this.orderid);
      }
      console.log(this.orderid)
    }, balance () {
      if (this.orderid.length == 0) {
        this.$message.error("未勾选订单")
      } else {
        let worksid = this.orderid.join();
        orderbalance(worksid).then(res => {
          console.log(res)
          if (res.data.code == 0) {
            this.$message.success(res.data.msg)
            this.orderdata();
          } else {
            this.$message.error(res.data.msg)
          }
        })
      }

    }
  },
  mounted () {
    this.orderdata();
  }

}
</script>

<style>
.qkcont {
  padding: 10px;
}

.nodata {
  text-align: center;
  line-height: 80px;
  color: #e6e6e6;
}

.btn1 {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}
.ordertable {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.biaoti {
  font-size: 14px;
}

.orderlist span {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  margin: 0 3px;
}

.urgent {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 24px;
  background: #ff1918;
  border-radius: 0px 4px 0px 4px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  line-height: 24px;
}

.ygdPage {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>