
import request from '@/utils/request'
import qs from 'qs'

// 稿件结算
export function orderbalance (works_id) {
  let data = {
    works_id
  }
  data = qs.stringify(data)
  return request({
    url: '/qikan/applyJieSuan',
    method: 'post',
    data
  })
}
//设置快递单号
export function courierInfo (id,courierNum) {
  let data = {
    id,courierNum
  }
  data = qs.stringify(data)
  return request({
    url: '/other/courierInfo',
    method: 'post',
    data
  })
}
//查询快递单号
export function courierInfoIn (page,limit,id) {
  let data = {
    page,limit,id
  }
  data = qs.stringify(data)
  return request({
    url: '/other/courierInfoIn',
    method: 'post',
    data
  })
}

// 待稿件结算

export function orderdaijiesuan (page,limit,qk_id) {
  let data = {
    page,limit,qk_id
  }
  data = qs.stringify(data)
  return request({
    url: '/qikan/daijiesuan',
    method: 'post',
    data
  })
}
// 确定完成
export function weitoyi (worksid) {
  let data = {
    worksid
  }
  data = qs.stringify(data)
  return request({
    url: '/qikan/qikanConfirm',
    method: 'post',
    data
  })
}

// 查看凭证列表
export function pingzheng (page,limit,qk_id) {
  let data = {
		page,
		limit,
    qk_id
  }
  data = qs.stringify(data)
  return request({
    url: '/platform/CashApplyQiKan',
    method: 'post',
    data
  })
}
